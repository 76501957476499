import Lottie from 'lottie-web';

export function contentLottieIcons() {
  const icons = document.querySelectorAll('#content-container [data-lottie-id]');

  icons.forEach((icon) => {
    const button = icon.parentNode;
    const animation = Lottie.loadAnimation({
      container: icon,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `/dist/lottie/${icon.dataset.lottieId}.json`,
    });

    button.addEventListener('mouseenter', () => animation.goToAndPlay(0));
  });
}

export function menuLottieIcons() {
  const icons = document.querySelectorAll('#menu [data-lottie-id]');

  icons.forEach((icon) => {
    const button = icon.parentNode;
    const animation = Lottie.loadAnimation({
      container: icon,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `/dist/lottie/${icon.dataset.lottieId}.json`,
    });

    button.addEventListener('mouseenter', () => animation.goToAndPlay(0));
  });
}
