import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import global from './global/global';
import mainNavigation from './global/mainNavigation';
import barba from '@barba/core';

// animations
import { contentLottieIcons, menuLottieIcons } from './animations/lottie-icons';
import initScrollAnimations from './animations/scroll';
import gsap from 'gsap';

gsap.install(window);

initScrollAnimations();
global();
menuLottieIcons();
contentLottieIcons();
mainNavigation();

// Alpine plugins
Alpine.plugin(collapse);

// init alpinejs
Alpine.start();

// barba.js page transitions
barba.init({
  preventRunning: true,
  transitions: [
    {
      name: 'to-and-from-home',
      leave(data) {
        return gsap.fromTo(
          data.current.container.querySelector('#animation'),
          {
            x: '-100%',
            skewX: '10deg',
          },
          {
            x: 0,
            skewX: 0,
          }
        );
      },
      after(data) {
        return gsap.fromTo(
          data.next.container.querySelector('#animation'),
          {
            x: 0,
          },
          {
            x: '100%',
          }
        );
      },

      // define a custom rule based on the trigger class
      custom: ({ trigger }) => {
        return trigger.classList && trigger.classList.contains('home-transition');
      },
    },
  ],
  views: [
    {
      namespace: 'home',
      afterEnter() {},
    },
    {
      namespace: 'uber-uns',
      afterEnter() {},
    },
    {
      namespace: 'kontakt',
      afterEnter() {},
    },
  ],
});

barba.hooks.after(() => {
  initScrollAnimations();
  global();
  contentLottieIcons();
  // don't scroll to top if there is an anchor link
  if (!window.location.href.includes('#')) {
    window.scrollTo(0, 0);
  }
});

// setTimeout(() => {
//   document.title = 'OIDA';
// }, 5000);
