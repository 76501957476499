import gsap, { Power1 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function () {
  gsap.registerPlugin(ScrollTrigger);

  const elsToFadeInUp = gsap.utils.toArray('[data-fadeInUp]');

  elsToFadeInUp.forEach((el) => {
    gsap.set(el, { autoAlpha: 0, y: 50 });
  });

  setTimeout(() => {
    ScrollTrigger.batch(elsToFadeInUp, {
      once: true,
      onEnter: (batch) => {
        gsap.to(batch, {
          overwrite: true,
          stagger: 0.1,
          duration: 0.65,
          autoAlpha: 1,
          y: 0,
        });
      },
    });
  }, 350);

  const elsToFadeIn = gsap.utils.toArray('[data-fadeIn]');
  elsToFadeIn.forEach((el) => gsap.set(el, { autoAlpha: 0 }));

  gsap.to(elsToFadeIn, {
    overwrite: true,
    stagger: {
      from: 'end',
      each: 0.08,
    },
    y: 0,
    duration: 0.4,
    ease: Power1.easeIn,
    autoAlpha: 1,
  });

  let mm = gsap.matchMedia();

  /**
   * Navbar
   */

  mm.add('(max-width: 1023px)', () => {
    const topBar = document.querySelector('.js-topbar');
    const mainArea = document.querySelector('.js-mainarea');
    const color = Array.from(document.querySelector('#navArea').classList).filter((className) => {
      return className.includes('bg-green');
    });

    gsap.to(topBar, {
      duration: 0.2,
      scrollTrigger: {
        trigger: mainArea,
        start: 'top top',
        end: 'top bottom',
        markers: false,
        toggleActions: 'play none none reverse',
        onEnter: () => {
          topBar.classList.add('bg-green-300');
          topBar.classList.remove(color);
        },
        onEnterBack: () => {
          topBar.classList.remove('bg-green-300');
          topBar.classList.add(color);
        },
      },
    });
  });

  /**
   * Footer
   */

  const footerContent = document.querySelector('#footerContent');
  const footerNavigation = document.querySelector('#footerNavigation');
  const navArea = document.querySelector('#navArea');

  if (navArea && footerContent && footerNavigation) {
    mm.add('(min-width: 1024px)', () => {
      const navTl = gsap.timeline({
        scrollTrigger: {
          trigger: footerContent,
          start: 'top center',
          toggleActions: 'play none none reverse',
          markers: false,
        },
        duration: 0.2,
        ease: 'easeIn',
      });
      navTl
        .to(navArea, { backgroundColor: 'rgb(108 147 145)' })
        .to(navArea.querySelectorAll('[data-fadeInUp],[data-fadeIn]'), { y: -10, autoAlpha: 0, stagger: 0.05 }, '<50%')
        .set(footerNavigation, { zIndex: 20, duration: 0 })
        .fromTo(
          footerNavigation.querySelectorAll('[data-fadeInDown]'),
          { y: -10, autoAlpha: 0 },
          { y: 0, autoAlpha: 1, stagger: 0.07 }
        );

      return () => {
        // optional
        // custom cleanup code here (runs when it STOPS matching)
      };
    });
  }
}
