import Alpine from 'alpinejs';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap';

export default function () {
  Alpine.data('nav', () => ({
    navIsOpen: false,
    scrollingDown: true,
    isMobile: false,
    menuColumnEls: [],
    menuEl: undefined,
    timelines: { open: undefined, close: undefined },

    checkIfMobile() {
      this.isMobile = window.innerWidth > 1024;
    },

    toggleNav() {
      if (!this.navIsOpen) {
        this.openNav();
      } else {
        this.closeNav();
      }
    },

    openNav() {
      if (this.timelines.open?.isActive()) {
        this.timelines.open?.pause();
      }
      if (this.timelines.close?.isActive()) {
        this.timelines.close?.pause();
      }
      gsap.set(this.$refs.closeButton, { opacity: 0 });

      if (this.timelines.open === undefined) {
        const tl = gsap.timeline();
        tl.fromTo(
          this.menuEl,
          {
            x: '-100%',
            skewX: '10deg',
          },
          {
            x: 0,
            skewX: 0,
            duration: 0.4,
            ease: 'linear',
          }
        );
        tl.fromTo(
          this.menuColumnEls,
          { opacity: 0 },
          {
            opacity: 1,
            delay: (i) => 0.1 * i,
            duration: 1,
          }
        );
        tl.fromTo(
          this.$refs.closeButton,
          {
            opacity: 0,
          },
          {
            duration: 0.8,
            opacity: 1,
            delay: 0.3,
          },
          '<'
        );

        this.timelines.open = tl;
      } else {
        this.timelines.open.restart();
      }

      this.navIsOpen = true;
      disableBodyScroll(this.$refs['innerNav'], { reserveScrollBarGap: true });
    },

    closeNav() {
      if (this.timelines.open?.isActive()) {
        this.timelines.open?.pause();
      }
      if (this.timelines.close?.isActive()) {
        this.timelines.close?.pause();
      }
      gsap.set(this.menuColumnEls, { opacity: 0 });
      if (this.timelines.close === undefined) {
        const tl = gsap.timeline();
        tl.fromTo(
          this.menuEl,
          {
            x: 0,
          },
          {
            x: '100%',
            duration: 0.55,
            onComplete: () => {
              enableBodyScroll(this.$refs['innerNav']);
              this.navIsOpen = false;
            },
          }
        );
        this.timelines.close = tl;
      } else {
        this.timelines.close.restart();
      }
    },

    scrolling() {
      this.scrollingDown = this.offsetTop < window.pageYOffset ? true : false;
      this.offsetTop = window.pageYOffset;
      this.navAtTop = this.offsetTop < 250 ? true : false;
      this.navIsMinimized = this.scrollingDown && this.offsetTop > 50 ? true : false;
    },

    init() {
      this.checkIfMobile();
      this.menuEl = this.$refs.innerNav;
      this.menuColumnEls = gsap.utils.toArray('[data-menu-column]');
      gsap.set(this.menuColumnEls, { opacity: 0 });
    },
  }));
}
